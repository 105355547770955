import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { ButtonSeverity } from "../../utils/shared.models";

@Component({
  selector: "twx-button-outlined",
  standalone: true,
  imports: [ButtonModule],
  templateUrl: "./button-outlined.component.html",
  styleUrl: "./button-outlined.component.scss",
})
export class ButtonOutlinedComponent {
  @Input() label!: string;
  @Input() disabled!: boolean;
  @Input() type!: string;
  @Input() icon!: string;
  @Input() severity!: ButtonSeverity;
  @Input() class = "";
  @Input() centeredText = false;

  @Output() buttonClicked = new EventEmitter<void>();
}
